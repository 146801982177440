
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'

@Component
export default class RadioCitationFormats extends Vue {
  @Prop({ default: true }) showLinkFormat!: boolean
  @Prop({ default: '' }) pageName!: string
  @PropSync('format', { type: String }) citationFormat!: string
  // citationFormat = 'mathscinet' // Should this be synced?
  radioName = 'Citation Format'
  storageName = 'radio_export_format_'
  loaded = false

  mounted() {
    if (this.citationFormat.length === 0) {
      this.citationFormat = 'mathscinet' // Set the default
    }
    this.loadLocalStorage()
    this.loaded = true
  }

  loadLocalStorage() {
    const savedFormat = localStorage.getItem(this.storageName + this.pageName)
    if (savedFormat) {
      this.citationFormat = savedFormat
    }
  }

  saveLocalStorage() {
    localStorage.setItem(this.storageName + this.pageName, this.citationFormat)
  }

  @Watch('citationFormat')
  onFormatChange() {
    if (this.loaded) {
      this.saveLocalStorage()
    }
  }
}
